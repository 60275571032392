const MilkRunToDo = () => import("./MilkRunToDo")
import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/milkrun/todo',
        name: 'MilkRunToDo',
        meta: {menu: 'milkrun'},
        component: MilkRunToDo,
        beforeEnter: ifAuthenticated
    },
]