<template>
    <v-container fluid class="flex-column overflow-y-auto" style="max-height: 90vh;">
        <m-loading :loading="$apollo.loading"/>
        <div class="display-3 mb-5">Programmi Piegatrua</div>
        <v-fade-transition>
            <v-overlay absolute v-if="copying">
                <v-progress-circular width="6" size="120" indeterminate color="info">
                    <v-row class="flex-column align-center">
                        <v-img transition="scale-transition" src="@/assets/logo.svg" width="90"></v-img>
                        <span class="text--primary" style="text-align: center;z-index: 6">Copia Programmi ... </span>
                    </v-row>
                </v-progress-circular>
            </v-overlay>
        </v-fade-transition>
        
        <v-row>
            <v-col>
                <v-card class="pl-2 pr-2 pt-1">
                    <v-card-title>
                        
                        <v-select :items="piegarine" label="Seleziona Sorgente" item-text="descrizione" item-value="codice" return-object
                                  :hint="`${sorgente?.codice} - ${sorgente?.descrizione}`"
                                  v-model="sorgente"/>
                    </v-card-title>
                    <v-card-text class="pl-1 pr-1">
                        <v-data-table
                            :loading="$apolloData.queries.programmisorgente.loading"
                            v-model="programmisorgente_selected"
                            show-select
                            single-select
                            dense
                            :headers="headers"
                            :items="programmisorgente"
                            item-key="id"
                            :search="search_sorgente"
                        >
                            <template v-slot:top>
                                <v-text-field
                                    v-model="search_sorgente"
                                    label="Cerca"
                                    clearable
                                    class="mx-4"
                                ></v-text-field>
                            </template>
                            <template v-slot:[`item.lastUpdate`]="{ item }">
                                {{ item.lastUpdate | moment("DD/MM/YYYY HH:mm") }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="flex-shrink-1 flex-grow-0">
                <v-btn color="green" :disabled="!programmisorgente_selected.length > 0 || sorgente.codice === destinazione.codice || copying"
                       @click="copyProgrammi">
                    <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col>
                <v-card class="pl-2 pr-2 pt-1">
                    <v-card-title>
                        <v-select :items="piegarine" label="Seleziona Destinazione" item-text="descrizione" item-value="codice" return-object
                                  :hint="`${sorgente?.codice} - ${sorgente?.descrizione}`"
                                  v-model="destinazione"/>
                    </v-card-title>
                    
                    <v-card-text class="pl-1 pr-1">
                        <v-data-table
                            :loading="$apolloData.queries.programmidestinazione.loading"
                            dense
                            :headers="headers"
                            :items="programmidestinazione"
                            item-key="id"
                            :search="search_destinazione"
                        >
                            <template v-slot:top>
                                <v-text-field
                                    v-model="search_destinazione"
                                    clearable
                                    label="Cerca"
                                    class="mx-4"
                                ></v-text-field>
                            </template>
                            <template v-slot:[`item.lastUpdate`]="{ item }">
                                {{ item.lastUpdate | moment("DD/MM/YYYY HH:mm") }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {Piegarine, ProgrammiDestinazione, ProgrammiSorgente} from "../../queries/piegarine.graphql";
    import MLoading from "@/components/include/MLoading.vue";
    import {polloMixin} from "@/mixins/pollo";
    
    export default {
        name: "ProgrammiPiega",
        mixins: [polloMixin],
        components: {MLoading},
        data: () => ({
            sorgente: undefined,
            destinazione: undefined,
            programmisorgente: [],
            programmidestinazione: [],
            programmisorgente_selected: [],
            programmidestinazione_selected: [],
            search_sorgente: '',
            copying: false,
            search_destinazione: '',
            headers: [
                {text: 'Nome', value: 'nomeFile'},
                {text: 'Ultima Modifica', value: 'lastUpdate'},
            ],
        }),
        apollo: {
            
            piegarine: {
                query: Piegarine,
                update(data) {
                    return data.piegarine.edges.map((edge) => edge.node)
                },
            },
            programmisorgente: {
                query: ProgrammiSorgente,
                variables() {
                    return {
                        piegarina: this.sorgente?.codice
                    }
                },
                update(data) {
                    return data.programmisorgente.edges.map((edge) => edge.node)
                },
                skip() {
                    return !this.sorgente
                },
            },
            programmidestinazione: {
                query: ProgrammiDestinazione,
                variables() {
                    return {
                        piegarina: this.destinazione?.codice
                    }
                },
                update(data) {
                    return data.programmidestinazione.edges.map((edge) => edge.node)
                },
                skip() {
                    return !this.destinazione
                },
            }
        },
        methods: {
            async copyProgrammi() {
                let programmi = this.programmisorgente_selected.map(p => p.nomeFile)
                let app = this
                app.copying = true
                app.$work.post('/progpiega/', {
                    cmd: "copy",
                    sorgente: app.sorgente.codice,
                    destinazione: app.destinazione.codice,
                    programmi: programmi
                }).then((response) => {
                    app.$toast.info(response.data.msg, {icon: 'mdi-info'});
                    app.$apollo.queries.programmidestinazione.refetch()
                    app.programmisorgente_selected = []
                }).finally(() => {
                    app.copying = false
                })
            }
        },
        watch: {
            sorgente() {
                this.programmisorgente_selected = []
                this.search_destinazione = ''
            },
            programmisorgente_selected(newVal) {
                if (newVal.length > 0) {
                    this.search_destinazione = newVal[0].nomeFile
                }
            }
        },
    }
</script>
