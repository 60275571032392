/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'

import * as Sentry from "@sentry/browser";


import _ from 'lodash';
import store from './store';

import vuetify from './plugins/vuetify';
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueMoment from 'vue-moment'
import VueWorker from 'vue-worker'
import '@/assets/global.css'
import localforage from "localforage";
import Vlf from 'vlf'
import socket from './socket'
import {ApolloClient, from, HttpLink, InMemoryCache} from '@apollo/client/core'
import {createApolloProvider} from '@vue/apollo-option'
import {LocalForageWrapper, persistCache} from 'apollo3-cache-persist';
import Api from './plugins/api'
import {RetryLink} from "@apollo/client/link/retry";
import {onError} from '@apollo/client/link/error'
import {captureException} from "@sentry/vue";
import {logErrorMessages} from '@vue/apollo-util'

const moment = require('moment')
const momentDurationFormatSetup = require("moment-duration-format")
momentDurationFormatSetup(moment)
require('moment/locale/it')


Sentry.init({
    Vue,
    dsn: "https://3c17c40d2fcd4c309f181dbb2e96b748@sentry.meskatech.com/37",
    autoSessionTracking: true,
    release: "work-frontend@" + process.env.VUE_APP_PACKAGE_VERSION,
    environment: process.env.VUE_APP_SENTRY_ENV,
    beforeSend(event, hint) {
        const error = hint.originalException;
        if (
            error?.message?.match(/database unavailable/i)
        ) {
            event.fingerprint = ["database-unavailable"];
        }
        /*
        * Filtro errori incurabili
        * */
        if (error && error.name === "DataCloneError") {
            return null;
        }
        if (error && error.name === "AxiosError" && error.message === "timeout of 1ms exceeded") {
            return null;
        }
        if (error && error.name === "AxiosError" && error.message === "Request aborted") {
            return null;
        }
        if (error && error.name === "AxiosError" && error.message === "Network Error") {
            return null;
        }
        if (error && error.name === "Error" && error.message === "Worker was terminated") {
            return null;
        }
        if (error && error.name === "Error" && error.message === "Resize must be passed a displayed plot div element.") {
            return null;
        }
        if (error && error.name === "TypeError" && error.message === "this.messageHandler is null") {
            return null;
        }
        return event;
    },
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
    logErrors: true
});
Sentry.addTracingExtensions();

Vue.config.productionTip = false
Vue.config.performance = true
Vue.prototype.$socket = socket


// Variabili Globali da ENV
Vue.prototype.$workUrl = window.location.protocol + "//" + process.env.VUE_APP_WORK_URL
Vue.prototype.$workWs = process.env.VUE_APP_WORK_WS
Vue.prototype.$sentryEnv = process.env.VUE_APP_SENTRY_ENV
Vue.prototype.$AppVersion = process.env.VUE_APP_PACKAGE_VERSION
Vue.prototype.$ThumborKey = process.env.VUE_APP_THUMBOR_KEY
Vue.prototype.$ThumborUrl = window.location.protocol + "//" + process.env.VUE_APP_THUMBOR_URL
Vue.prototype.$pouchUrl = window.location.protocol + "//" + process.env.VUE_APP_POUCHDB_URL.split('//')[0] + '//' + process.env.VUE_APP_POUCHDB_USER + ':' + process.env.VUE_APP_POUCHDB_PASSWORD + '@' + process.env.VUE_APP_POUCHDB_URL.split('//')[1]


// il caricamento della versione viene fatto su vue.config.js
// con il pre-push aumento la versione "yarn version --patch"

Vue.use(VueMoment, {moment});
Vue.use(VueWorker);
Vue.use(Vlf, localforage);
Vue.use(Api, {url: Vue.prototype.$workUrl + '/api/', store: store, version: Vue.prototype.$AppVersion});
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = window.location.protocol + "//" + process.env.VUE_APP_WORK_URL;


Vue.filter('round', function (value, precision) {
    if (precision === undefined) {
        precision = 2
    }
    if (!value) return ''
    if (value === 0) return 0
    return _.round(value, precision)
})

Vue.filter('toEur', function (value, precision = 2, hideZero = false) {
    if (typeof value !== "number") {
        value = _.round(Number(value), precision)
    } else {
        value = _.round(value, precision)
    }
    if (hideZero && value === 0) {
        return ''
    }
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: precision,
        minimumSignificantDigits: 1
    });
    return formatter.format(value);
});

Vue.filter('noZero', function (value, precision = 2) {
    if (typeof value !== "number") {
        value = _.round(Number(value), precision)
    } else {
        value = _.round(value, precision)
    }
    if (value === 0) {
        return ''
    }
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'decimal'
    });
    return formatter.format(value);
});

Vue.filter('toQty', function (value, precision = 2) {
    if (typeof value !== "number") {
        value = _.round(Number(value), precision)
    } else {
        value = _.round(value, precision)
    }
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'decimal'
    });
    return formatter.format(value);
});
Vue.filter('toPerc', function (value) {
    if (typeof value !== "number") {
        value = Number(value)
    }
    if (value <= 0) {
        return ''
    }
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'percent',
    });
    return formatter.format(value / 100);
});
Vue.filter('toPerc2', function (value) {
    if (typeof value !== "number") {
        value = Number(value)
    }
    if (value === 0) {
        return ''
    }
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'percent',
    });
    return formatter.format(value / 100);
});
Vue.filter('join', function (array, separator) {
    return _.join(array, separator.replace(/\n/g, "<br />"));
});

Vue.filter('cut', function (text, stop) {
    return text.slice(0, stop)
})

Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('boringduration', function (array) {
    let duration = moment.duration(array[0], array[1]).format("h:mm:ss", {trim: false});
    // se il primo carattere non è un numero, lo rimuovo
    if (isNaN(duration.charAt(0))) {
        duration = duration.slice(1)
    }
    return duration
});

Vue.filter('highlight', function (text, search) {
    if (!search) {
        return text
    }
    return text.replace(new RegExp(search, 'gi'), '<span class="highlightedText">$&</span>')
});

export const bus = new Vue();

// Apollo
const cache = new InMemoryCache()
persistCache({
    cache,
    storage: new LocalForageWrapper(localforage),
    serialize: false
}).then(() => {


    const additiveLink = from([
        new RetryLink(),
        new HttpLink({
            uri: `${window.location.protocol + "//"}${process.env.VUE_APP_WORK_URL}/graphql/`,
            headers: {Authorization: 'Token ' + store.getters.token},
        })
    ]);

    // Handle errors
    const errorLink = onError(error => {
        captureException(error)
        if (process.env.NODE_ENV !== 'production') {
            logErrorMessages(error)
        }
    })

    const apolloClient = new ApolloClient({
        cache,
        link: errorLink.concat(additiveLink)
    })

    const apolloProvider = createApolloProvider({
        defaultClient: apolloClient,
    })

    Vue.config.globalProperties = Vue.config.globalProperties || {}
    Vue.prototype.$apolloProvider = apolloProvider
    apolloProvider.install(Vue)

    window.vm = new Vue({
        store,
        vuetify,
        router,
        render: h => h(App)
    }).$mount('#app')
})


window.testBarcode = function (odp) {
    /*
     TEST BARCODE
     lanciare con:
     testBarcode('ODP1007384')
    */
    for (let x in odp) {
        window.dispatchEvent(new KeyboardEvent('keydown', {
            'key': odp[x]
        }));
    }
    window.dispatchEvent(new KeyboardEvent('keydown', {
        'key': "Enter"
    }));
}
