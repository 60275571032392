<template>
    <div>
        <v-btn color="info" small @click="dialog=true">
            <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" v-if="nc" width="40vw" style="position: relative">
            <v-progress-linear indeterminate v-if="loading"
                               style="position: absolute;top: 0;left: 0"></v-progress-linear>
            <v-card>
                <v-card-title>
                    Aggiorna Info {{ nc.numero }} del {{ nc.data_inserimento | moment('DD/MM/Y') }}
                </v-card-title>
                <v-card-text style="overflow-x: hidden">
                    <v-form v-model="validform" ref="ncform" lazy-validation>
                        <v-row>
                            
                            <v-col>
                                
                                <v-select label="Origine" v-model="nc.nc_origine_id" :items="origini" item-text="label"
                                          item-value="id"
                                          :rules="[rules.required]"></v-select>
                                <v-select label="Causa" v-model="nc.nc_causa_id" :items="cause" item-text="label"
                                          item-value="id"
                                          :rules="[rules.required]"></v-select>
                                <v-textarea rows="3" label="Problema"
                                            :rules="[rules.required]" v-model="nc.problema"
                                ></v-textarea>
                                <v-textarea rows="3" label="Correzione"
                                            :rules="[rules.required]"
                                            v-model="nc.correzione"></v-textarea>
                                <v-textarea rows="3" label="Azione Correttiva"
                                            :rules="[rules.required]" v-model="nc.soluzione"
                                ></v-textarea>
                                <v-checkbox label="Risolta" v-model="risolta"></v-checkbox>
                            </v-col>
                            <v-col cols="2" class="ml-1 mb-1">
                                <v-lazy>
                                    <v-row dense>
                                        <v-col v-for="(f,f_id) in nc.foto" v-bind:key="f_id" class="shrink">
                                            <v-row dense class="flex-nowrap">
                                                <v-col>
                                                    <v-dialog v-model="dialog_pic[f_id]">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-img :src="thumburl + f.code + '/'"
                                                                   style="cursor: zoom-in"
                                                                   :width="80" :height="80"
                                                                   v-bind="attrs"
                                                                   class="thumb"
                                                                   v-on="on">
                                                            </v-img>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title style="text-align: center">{{
                                                                    f.name
                                                                }}
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-img style="cursor: zoom-out;"
                                                                       :src="fullurl + f.code + '/'" contain
                                                                       height="80vh"
                                                                       @click="$set(dialog_pic,f_id,false)">
                                                                </v-img>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox color="red" v-model="images_delete" :value="f.code"
                                                                title="Elimina">
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-lazy>
                            </v-col>
                        </v-row>
                        <v-divider>
                        </v-divider>
                        
                        <v-row dense class="flex-column">
                            <v-col>
                                <v-file-input prepend-icon="mdi-camera" counter show-size class="mt-5"
                                              label="Carica Altre Foto" placeholder="Clicca per caricare"
                                              @change="anteprima" v-model="images"
                                              multiple></v-file-input>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col v-for="(img,img_id) in images_url" v-bind:key="img_id">
                                        <v-img :width="80" :height="80" :src="img.src"></v-img>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider/>
                <v-card-text class="mt-2">
                    <template v-if="nc.nc_gestione">
                        <span class="caption">gestione:</span> {{ nc.nc_gestione }}<br/>
                    </template>
                    <template v-if="nc.nc_causa">
                        <span class="caption">causa:</span> {{ nc.nc_causa }}<br/>
                    </template>
                    <template v-if="nc.nc_origine">
                        <span class="caption">origine:</span> {{ nc.nc_origine }}<br/>
                    </template>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange darken-1" @click="dialog = false">
                        Annulla
                    </v-btn>
                    <v-btn color="green darken-1" @click="setData(nc,images)" :disabled="loading || !validform">
                        Aggiorna
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    
    export default {
        name: 'NcEdit',
        components: {},
        props: {
            input: {},
            articolo: {String, required: true},
            getdata: {Function, required: true},
        },
        data() {
            return {
                dialog: false,
                images: undefined,
                images_url: undefined,
                images_delete: [],
                dialog_pic: [],
                loading: false,
                validform: false,
                risolta: true,
                nc: undefined,
                cause: undefined,
                origini: undefined,
                rules: {
                    required: value => {
                        if (value === null) return 'Richiesto'
                        if (value === undefined) return 'Richiesto'
                        if (value === "") return 'Richiesto'
                        if (value === " ") return 'Richiesto'
                        return true
                    }
                },
                
                // eslint-disable-next-line no-undef
                thumburl: window.location.protocol + "//" + process.env.VUE_APP_WORK_URL + '/commesse/thumbnail/',
                // eslint-disable-next-line no-undef
                fullurl: window.location.protocol + "//" + process.env.VUE_APP_WORK_URL + '/commesse/showfile/',
            }
        },
        watch: {
            dialog: function () {
                if (this.dialog) {
                    this.nc = this.input;
                    this.risolta = this.nc.risolta === 2;
                    this.images_delete = [];
                }
            },
            
        }
        ,
        created() {
            this.getRelated()
        }
        ,
        mounted() {
            let app = this
            this.$vlf.getItem('nc_cause').then(res => {
                if (res) {
                    app.cause = res
                } else {
                    this.getRelated()
                }
            });
            this.$vlf.getItem('nc_origini').then(res => {
                if (res) {
                    app.origini = res
                } else {
                    this.getRelated()
                }
            });
        }
        ,
        methods: {
            getRelated() {
                let app = this
                this.$work.get('/arts/nc/', {related: true}).then((response) => {
                    app.cause = response.data.cause
                    app.origini = response.data.origini
                }).finally(() => {
                    this.$vlf.setItem('nc_cause', app.cause)
                    this.$vlf.setItem('nc_origini', app.origini)
                })
                
            }
            ,
            setData: function (nc, images) {
                this.loading = true
                let formData = new FormData();
                if (images !== undefined) {
                    let filen = 1
                    images.forEach(file => {
                        formData.append('file_' + filen, file, file.name);
                        filen += 1;
                    });
                    this.images = undefined
                    this.images_url = undefined
                }
                formData.append('numero', nc.numero);
                formData.append('riga', nc.riga);
                
                formData.append('nc_causa_id', nc.nc_causa_id);
                formData.append('nc_origine_id', nc.nc_origine_id);
                formData.append('risolta', this.risolta);
                
                if (nc.problema) {
                    formData.append('problema', nc.problema);
                }
                if (nc.correzione) {
                    formData.append('correzione', nc.correzione);
                }
                if (nc.soluzione) {
                    formData.append('soluzione', nc.soluzione);
                }
                
                formData.append('images_delete', this.images_delete);
                let custom_headers = {
                    'Content-Type': 'multipart/form-data'
                }
                this.$work.post('/arts/nc/' + this.articolo + '/', formData, false, custom_headers).then(() => {
                    this.dialog = false
                    this.getdata()
                }).finally(() => {
                    this.loading = false
                })
            }
            ,
            anteprima() {
                this.images_url = []
                this.images.forEach(element => {
                    this.images_url.push({'src': URL.createObjectURL(element)})
                });
            }
            ,
        }
    }
</script>
<style scoped>

::v-deep th {
    height: auto !important;
}

/*
::v-deep td {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: gray;
}

::v-deep td:last-child {
    border-right-style: none;
}
*/

</style>