const DaNestare = () => import("./DaNestare")
const Programmi = () => import("./Programmi")
const InTaglio = () => import("./InTaglio")
const Sbancalatura = () => import("./Sbancalatura")
const DaUbicare = () => import("./DaUbicare")
export default [
    {
        path: '/laser/intaglio/',
        name: 'laser-intaglio',
        meta: {menu: 'laser'},
        component: InTaglio,
    },
    {
        path: '/laser/danestare/',
        name: 'laser-danestare',
        meta: {menu: 'laser'},
        component: DaNestare,
    },
    {
        path: '/laser/programmi/',
        name: 'laser-programmi',
        meta: {menu: 'laser'},
        component: Programmi,
    }, {
        path: '/laser/sbancalatura/',
        name: 'laser-sbancalatura',
        meta: {menu: 'laser'},
        component: Sbancalatura,
    },
    {
        path: '/laser/daubicare/',
        name: 'laser-daubicare',
        meta: {menu: 'laser'},
        component: DaUbicare,
    },
]