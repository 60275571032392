export default {
    title: 'Staff',
    icon: 'mdi-chair-rolling',
    groups: ['Amministrazione'],
    submenu: [
        {
            title: 'Controllo Prezzi',
            route: {name: 'staff-prezzi'},
            groups: ['Amministrazione']
        },
         {
            title: 'Controllo Odv',
            route: {name: 'staff-controllodv'},
            groups: ['Amministrazione']
        }, {
            title: 'Budget Previsionale',
            route: {name: 'staff-budgetprevisionale'},
            groups: ['Amministrazione']
        },
         {
            title: 'Fix Storico',
            route: {name: 'staff-fixstorico'},
            groups: ['Amministrazione']
        },


    ]
}