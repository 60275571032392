<template>
    <v-btn :small="small" @click="setValue" :color="btcolor" class="mr-1 ml-1">
        <span v-if="btcolor">
            {{ label }}
        </span>
        <span v-else :style="{'text-decoration': status === undefined ?  stages===3 ? 'line-through':'auto': 'auto'}">
            {{ label }}
        </span>
    </v-btn>
</template>
<script>
    export default {
        name: 'BtSiNoNull',
        props: {
            name: String,
            status: String,
            callback: {},
            small: {type: Boolean, default: true},
            label: String,
            stages: {type: Number, default: 3},
        },
        computed: {
            btcolor: function () {
                if (this.status === 'no') {
                    return 'red'
                }
                if (this.status === 'si') {
                    return 'green'
                }
                return undefined
            }
        },
        methods: {
            setValue: function () {
                if (this.stages === 3) {
                    if (this.status === 'si') {
                        return this.callback(this.name, 'no')
                    }
                    if (this.status === 'no') {
                        return this.callback(this.name, undefined)
                    }
                    if (this.status === undefined) {
                        return this.callback(this.name, 'si')
                    }
                }
                if (this.stages === 2) {
                    if (this.status === 'si') {
                        return this.callback(this.name, undefined)
                    }
                    if (this.status === undefined) {
                        return this.callback(this.name, 'si')
                    }
                }
            }
        }
    }
</script>
<style>
.bigfield input {
    font-size: 2.5em;
    max-height: 50px;
    line-height: 100%;
}

</style>