const Ordine = () => import("./Ordine")
const OrdineVendita = () => import("./OrdineVendita")
const OdvClienti = () => import("./OdvClienti")
const OdvCliente = () => import("./OdvCliente")
const Proposte = () => import("./Proposte")
const Offerte = () => import("./Offerte")
import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/ordini/cliente/:codice/:indirizzo?',
        name: 'ordini-cliente',
        meta: {menu: 'ordini'},
        component: OdvCliente,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ordini/clienti/',
        name: 'ordini-clienti',
        meta: {menu: 'ordini'},
        component: OdvClienti,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ordine/:codice',
        name: 'Ordine',
        meta: {menu: 'ordine'},
        component: Ordine,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ordinev/:codice',
        name: 'OrdineVendita',
        meta: {menu: 'ordinev'},
        component: OrdineVendita,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ordini/proposte/',
        name: 'ordini-proposte',
        meta: {menu: 'ordini'},
        component: Proposte,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ordini/offerte/',
        name: 'ordini-offerte',
        meta: {menu: 'ordini'},
        component: Offerte,
        beforeEnter: ifAuthenticated
    },
]