const Efficenza = () =>  import("./Efficenza")

const OpcUa = () =>  import("./OpcUa")
const Reparto = () =>  import("./Reparto")
const DashboardHome = () =>  import("./DashboardHome")
import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/dashboard/efficenza/',
        name: 'Efficenza',
        meta: {menu: 'dashboard'},
        component: Efficenza,
    },
    {
        path: '/dashboard/opcua/',
        name: 'OpcUa',
        meta: {menu: 'dashboard'},
        component: OpcUa,
        beforeEnter: ifAuthenticated
    },

    {
        path: '/dashboard/reparto/:reparto',
        name: 'Reparto',
        meta: {menu: 'dashboard'},
        props: true,
        component: Reparto
    },
    {
        path: '/dashboard/',
        name: 'DashboardHome',
        meta: {menu: 'dashboard'},
        component: DashboardHome
    },
]