const Lamiera = () => import("./Lamiera")
const ContoLavoro = () => import("./ContoLavoro")
const ContoLavoroListino = () => import("./ContoLavoroListino")
const Ricevimenti = () => import("./Ricevimenti")

export default [
    {
        path: '/acquisti/lamiera/',
        name: 'acquisti-lamiera',
        meta: {menu: 'acquisti'},
        component: Lamiera,
    },
    {
        path: '/acquisti/contolavoro/',
        name: 'acquisti-contolavoro',
        meta: {menu: 'acquisti'},
        component: ContoLavoro,
    }, {
        path: '/acquisti/contolavorolistino/:fornitore/:nome/',
        name: 'acquisti-contolavorolistino',
        meta: {menu: 'acquisti'},
        component: ContoLavoroListino,
    },
    {
        path: '/acquisti/ricevimenti/:fornitore?',
        name: 'acquisti-ricevimenti',
        meta: {menu: 'acquisti'},
        component: Ricevimenti,
    },
]