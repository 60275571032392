import store from "../../store"

const SpoolerState = () => import("./SpoolerState")
const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/spooler/stato/',
        name: 'spooler-stato',
        meta: {menu: 'Stampe'},
        component: SpoolerState,
        beforeEnter: ifAuthenticated
    },
]