import _ from 'lodash'

export let polloMixin = {
    data: () => ({}),
    computed: {
        socketMessage() {
            return this.$store.state.socketmessage
        },
    },
    created() {
        let app = this
        // aggiorna on create
        /*
        _.forEach(app.$apollo.queries, (query) => {
            query.refetch()
        })
        */
        // attiva refresh su focus
        if (window) {
            window.addEventListener('focus', function () {
                _.forEach(app.$apollo.queries, (query) => {
                    query.refetch()
                })
            })
        }
    },
}