<template>
    <v-app id="work" cla>
        <v-navigation-drawer v-model="drawer" app dark v-if="isLoggedIn && !totem">
            
            <template v-slot:img>
                <v-img src="@/assets/drawer.jpg" height="100%" style="filter: brightness(0.4);"></v-img>
            </template>
            
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        Menù
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ userdata.username }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <v-list
                dense
                nav
                v-if="isLoggedIn"
            >
                <v-list-item
                    v-for="item in validMenu(workMenu)"
                    :key="item.title"
                    :to="firstRoute(item)"
                    @click="drawer = false"
                >
                    <template>
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list>
            
            <v-row class="caption" style="position: absolute;bottom: 20px;right: 20px;">Ver. {{ $AppVersion }} - {{ $sentryEnv }}</v-row>
        </v-navigation-drawer>
        <v-card v-if="!totem">
            <v-app-bar
                app
                dense
                color="primary"
                elevate-on-scroll
            >
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                <v-badge overlap offset-y="20" color="red" :content="$sentryEnv" :value="$sentryEnv !== 'production'">
                    <router-link to="/" class="d-none d-md-block">
                        <v-img v-if="$vuetify.theme.dark"
                               alt="Mecom Logo"
                               contain
                               src="@/assets/logo.png"
                               transition="scale-transition"
                               height="40"
                               width="200"
                        />
                        <v-img v-else
                               alt="Mecom Logo"
                               contain
                               src="@/assets/logo_light.png"
                               transition="scale-transition"
                               height="40"
                               width="200"
                        />
                    
                    </router-link>
                </v-badge>
                <v-toolbar-title class="ml-4 mt-4">
                    <v-icon class="mr-1">{{ areaIcon }}</v-icon>
                    {{ areaTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <search-box v-if="isLoggedIn && $router.currentRoute.name !== 'Search' && canSearch"></search-box>
                <!--
                <v-combobox
                    v-if="isLoggedIn && $router.currentRoute.name !== 'Search' && canSearch"
                    v-model="searchModel"
                    :loading="isSearching"
                    :items="searchResponse.hits.hits"
                    :search-input.sync="searchSync"
                    v-on:keyup.enter="searchEnter"
                    no-filter
                    class="mx-4 mt-8"
                    clearable
                    hide-no-data
                    color="white"
                    label="Cerca Articoli, Ordini"
                    placeholder="Inizia a scrivere per cercare"
                    prepend-icon="mdi-database-search"
                    item-text="_source.descrizione"
                    item-value="_source.codice"
                    return-object
                    id="main-search"
                    ref="searchCb"
                >
                    <template v-slot:prepend-item>
                        <v-row class="mr-1 ml-1">
                            <v-col>
                                <v-btn-toggle multiple v-model="searchFilter" class="flex flex-wrap">
                                    <v-btn x-small value="art" active-class="teal darken-3">ART</v-btn>
                                    <v-btn x-small value="odp" active-class="teal darken-3">ODP</v-btn>
                                    <v-btn x-small value="odv" active-class="teal darken-3">ODV</v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col style="text-align: right">
                                <v-btn :disabled="searchSync===undefined || searchSync===null || searchSync === ''"
                                       x-small
                                       :to="{name: 'Search', params: {query: searchSync }}"
                                       @click="$refs.searchCb.blur()">
                                    <v-icon>mdi-fit-to-screen</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item="{ item }"
                              :class="[
                                  item._source.priorita !== undefined ?  item._source.itmsta === '3' ? 'saldato' : 'priorita-' + item._source.priorita:''
                                  ]">
                        <v-list-item-avatar tile>
                            <bt-disegno :width="50" :articolo="item._source.articolo"
                                        :disegno="item._source.disegno"
                                        :pdfShow="false"
                            ></bt-disegno>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ item._source.codice }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item._source.descrizione }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-row dense class="flex-column">
                                <v-col class="mb-0 pb-0">{{ item._source.route }}</v-col>
                                <v-col class="caption mt-0 pt-0 text-right"
                                       :style="{'color': item._score >=2 ? 'green':''}">score:{{
                                        item._score | toQty
                                    }}
                                </v-col>
                            </v-row>
                        </v-list-item-icon>
                    </template>
                
                </v-combobox>
                -->
                <div class="display-1 pr-5 d-none d-sm-none"> {{ clock }}</div>
                <!-- THEME SWITCHAROO -->
                <v-btn icon @click="switchTheme()">
                    <v-icon>mdi-theme-light-dark</v-icon>
                </v-btn>
                <!-- POPUP PROBLEMA -->
                <v-btn
                    icon
                    color="orange"
                    v-on:click.stop="problema.url=$router.currentRoute.fullPath;problema.section=$router.currentRoute.name;problema_dialog=true;"
                    v-if="isLoggedIn"
                    :disabled="problema_dialog !== false"
                >
                    <v-icon>mdi-lifebuoy</v-icon>
                    <v-progress-circular
                        v-if="problema.working !== false"
                        class="ml-1"
                        :size="20"
                        :width="2"
                        color="info"
                        indeterminate
                    ></v-progress-circular>
                </v-btn>
                <v-dialog
                    v-model="problema_dialog"
                    persistent
                    max-width="50vh"
                >
                    
                    <v-card>
                        <v-card-title class="headline text-no-wrap">
                            Segnala un problema su Work
                        </v-card-title>
                        <v-card-text>
                       <span class="mt-2 mb-2 caption">
                        Utilizza questa finestra per segnalare un problema o un bug con l'interfaccia di Work,
                           riempi i campi e clicca segnala
                        </span>
                            <v-form v-model="form_problema">
                                <v-text-field
                                    label="Operatore:"
                                    v-model="problema.user"
                                    :rules="[v => v !== '' || 'Scrivi il tuo nome',]"
                                    required
                                ></v-text-field>
                                <v-textarea
                                    placeholder="Descrivi il problema:"
                                    v-model="problema.testo"
                                    :rules="[v => v !== '' || 'Campo Richiesto',]"
                                    required
                                ></v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="orange darken-1" @click="problema_dialog = false">
                                Annulla
                            </v-btn>
                            <v-btn color="green darken-1"
                                   :disabled="!form_problema || problema.working"
                                   v-on:click="inviaSegnalazione()">
                                Invia Segnalazione
                            
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
                <span class="mr-2" v-if="isLoggedIn">{{ userdata.full_name }}</span>
                <v-lazy>
                    <v-btn icon v-if="avatar?.image_data" :href="avatar?.profile" target="_blank">
                        <v-avatar color="orange" size="40">
                            <img alt="avatarrr" :src="avatar?.image_data">
                        </v-avatar>
                    </v-btn>
                </v-lazy>
                <v-btn icon v-on:click="doLogout" v-if="isLoggedIn">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
                
                <template v-slot:extension v-if="subMenu">
                    <v-tabs align-with-title optional color="white">
                        <v-tabs-slider color="yellow"></v-tabs-slider>
                        <v-tab v-for="(item,key) in subMenu" v-bind:key="key" :to="item.route">
                            <v-badge v-if="item.badge"
                                     color="orange"
                                     :content="getBadge(item)"
                                     :value="badges[item.route.name].value > 0"
                                     :title="item.badge.tooltip"
                            >
                                {{ item.title }}
                            </v-badge>
                            <div v-else>{{ item.title }}</div>
                        </v-tab>
                    </v-tabs>
                    <v-fab-transition>
                        <v-btn
                            fab small absolute bottom right elevation="3"
                            class="orange mr-2" :title="item.title" v-on:click="fabClick(item.event)"
                            v-for="(item,key) in floating_buttons[$router.currentRoute.name]" v-bind:key="key"
                        >
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-btn>
                    </v-fab-transition>
                
                </template>
            
            </v-app-bar>
        </v-card>
        <v-main>
            <router-view
                @show-pdf="showPdf"
            />
            <v-overlay :absolute="true" z-index="10" :opacity="0.98" v-if="requestpin">
                <v-card fluid>
                    <v-card-title>
                        Autorizzazione Team Leader Richiesta
                    </v-card-title>
                    <v-card-text>
                        <v-form v-model="form_pin">
                            <v-text-field
                                class="bigfield"
                                ref="pin"
                                label="Pin:"
                                hint="Inserisci il tuo pin per abilitare la modifica"
                                type="password"
                                v-model="pin"
                                :rules="[v => v !== '' || 'Campo Obbligatorio',rules.pinok,rules.pinok2]"
                                required
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange darken-1" to="/">
                            Annulla
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-overlay>
        </v-main>
        <!-- PDF VIEWER OVERLAY -->
        <v-overlay :absolute="true" :value="pdf.overlay" style="z-index: 99999">
            <v-container fluid style="width: 95vw;height: 95vh" class="mb-5">
                <v-system-bar window color="green">
                    <span>{{ pdf.title }}</span>
                    <v-spacer>
                    </v-spacer>
                    <v-icon v-on:click="pdf.overlay=false">mdi-close</v-icon>
                </v-system-bar>
                <pdf-viewer
                    :pdf="pdf.url"
                    :config="pdf.config"
                    @open="PdfopenHandler"
                    @after-created="PdfafterCreated"
                    @pages-rendered="PdfRendered"
                    theme="dark"
                ></pdf-viewer>
                <v-progress-linear indeterminate v-if="pdfloading"></v-progress-linear>
            </v-container>
        </v-overlay>
        
        <!-- SNACKBAR NUOVA RELEASE --->
        <v-snackbar
            v-model="reloadSnack"
            timeout="30000"
            absolute
            right
            bottom
            vertical
            color="info"
            class="mb-10 mr-5"
        >
            <v-row dense align="center">
                <v-col class="text-h6">
                    Nuova Versione disponibile
                </v-col>
                <v-progress-linear
                    v-if="reloadSnack"
                    indeterminate
                    color="white"
                    class="mb-0"
                >
                </v-progress-linear>
            </v-row>
        </v-snackbar>
    </v-app>
</template>


<script>
    
    
    import dashboard_menu from "./components/dashboard/_menu"
    import laser_menu from "./components/laser/_menu"
    import staff_menu from "./components/staff/_menu"
    import acquisti_menu from "./components/acquisti/_menu"
    import av_menu from "./components/avanzamenti/_menu"
    import tempi_menu from "./components/tempi/_menu"
    import milkrun_menu from "./components/milkrun/_menu"
    import magazzino_menu from "./components/magazzino/_menu"
    import ordini_menu from "./components/ordini/_menu"
    import qualita_menu from "./components/qualita/_menu"
    import fornitore_menu from "./components/fornitore/_menu"
    import spooler_menu from "./components/spooler/_menu"
    import attrezzature_menu from "./components/attrezzature/_menu"
    
    import PdfViewer from "vue-pdf-app";
    import "vue-pdf-app/dist/icons/main.css";
    import _ from 'lodash';
    import {bus} from "./main";
    import {teamLeaderMixin} from "./mixins/teamleader";
    import SearchBox from "./components/include/SearchBox.vue";
    
    export default {
        name: 'App',
        mixins: [teamLeaderMixin],
        components: {
            SearchBox,
            PdfViewer
        },
        data: () => ({
            clock: '00:00:00',
            drawer: false,
            versiontimer: undefined,
            reloadSnack: false,
            isSearching: false,
            searchModel: null,
            searchSync: null,
            searchTimer: undefined,
            pdfloading: false,
            totem: false,
            avatar: undefined,
            searchResponse: {
                hits: {
                    hits: []
                }
            },
            searchFilter: [
                'art', 'odp', 'odv'
            ],
            searchDescLimit: 60,
            floating_buttons: {},
            form_problema: undefined,
            problema_dialog: false,
            problema: {
                project: 'work',
                subsystem: 'Dashboard',
                url: '',
                section: '',
                working: false,
                testo: '',
                user: '',
                email: ''
            },
            
            badges: {},
            workMenu: {
                ordini: ordini_menu,
                avanzamenti: av_menu,
                milkrun: milkrun_menu,
                magazzino: magazzino_menu,
                attrezzature: attrezzature_menu,
                tempi: tempi_menu,
                laser: laser_menu,
                staff: staff_menu,
                acquisti: acquisti_menu,
                qualita: qualita_menu,
                dashboard: dashboard_menu,
                fornitore: fornitore_menu,
                spooler: spooler_menu
            },
            pdf: {
                overlay: false,
                url: '#',
                title: '',
                config: {
                    sidebar: {
                        viewThumbnail: true,
                        viewOutline: true,
                        viewAttachments: true,
                    },
                },
            },
            newBarcode: false,
            barcodeData: undefined,
            lastKeyTime: performance.now(),
            lastKey: undefined
        }),
        created() {
            let app = this
            window.removeEventListener('keydown', app.barcodeListener);
            window.addEventListener("keydown", app.barcodeListener);
            if (localStorage.darkTheme) {
                this.$vuetify.theme.dark = localStorage.darkTheme === 'true'
            } else {
                localStorage.darkTheme = true
            }
            
            bus.$on('show-pdf', function (item) {
                app.showPdf(item)
            })
            bus.$on('avatar', function (item) {
                app.avatar = item
            })
            this.versiontimer = setInterval(this.checkVersion, 300000)
            setInterval(this.updateClock, 1000);
            setTimeout(this.checkVersion, 2000)
        },
        async mounted() {
            this.$moment.locale('it');
            document.title = 'Work'
            this.totem = this.$route.query?.totem === 'true'
            this.$vlf.getItem('config_search').then(value => {
                if (value) {
                    this.searchFilter = value
                }
            })
            this.$vlf.getItem('avatar').then(value => {
                this.avatar = value
            })
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.barcodeListener)
            clearInterval(this.versiontimer)
        },
        methods: {
            search: _.debounce(function (val) {
                let app = this
                if ((val === undefined) || (val === null) || (val.length < 1)) {
                    app.searchResponse = {
                        hits: {
                            hits: []
                        }
                    }
                    return
                }
                
                app.isSearching = true
                this.$work.post("search/", {
                    'q': val,
                    't_odv': app.searchFilter.indexOf('odv') > -1,
                    't_odp': app.searchFilter.indexOf('odp') > -1,
                    't_art': app.searchFilter.indexOf('art') > -1
                }).then(response => {
                    if ((response !== undefined) && (app.searchSync !== null)) {
                        if (response.data.hits.total === 0 && (app.searchFilter.indexOf('odv') <= -1 || app.searchFilter.indexOf('odp') <= -1 || app.searchFilter.indexOf('art') <= -1)) {
                            app.searchFilter = ['odv', 'odp', 'art']
                        }
                        app.searchResponse = Object.freeze(response.data)
                    }
                }).catch(err => {
                    console.log(err)
                }).finally(() => (app.isSearching = false))
            }, 500),
            searchEnter: function (event) {
                if (event.keyCode === 13) {
                    let query = event.currentTarget.value
                    console.log("Search Enter", query)
                    if (query.length > 0) {
                        this.$router.push({name: 'Search', params: {query: this.searchSync}})
                    }
                }
            },
            isCapslock: function (e) {
                let charCode = e.keyCode
                let shifton = false
                
                if (e.shiftKey) {
                    shifton = e.shiftKey
                } else if (e.modifiers) {
                    shifton = !!(e.modifiers & 4)
                }
                if (charCode >= 97 && charCode <= 122 && shifton) {
                    return true
                }
                return (charCode >= 65 && charCode <= 90 && !shifton)
            },
            validMenu: function (menu) {
                // menù filtrato
                let app = this
                return _.filter(menu, function (o) {
                    if ((o.groups !== undefined) && (_.intersection(app.userdata.groups, o.groups).length === 0)) {
                        return false
                    }
                    return !((o.admin) && (app.$store.state.user.userdata.admin === false));
                })
            },
            switchTheme: function () {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
                localStorage.darkTheme = this.$vuetify.theme.dark
            },
            onEnter: function () {
                let app = this
                if ((this.searchSync !== undefined) && (this.searchSync.length >= 3)) {
                    let q = app.$data.searchSync
                    app.$data.searchEntries = []
                    this.$router.push({name: "Search", params: {query: q}}).then(function () {
                            setTimeout(function () {
                                app.$data.searchResponse.hits.hits = []
                            }, 50)
                        }
                    )
                }
            },
            inviaSegnalazione: async function () {
                let app = this;
                this.$work.post('/yt/creaticket/', app.problema).then(function (response) {
                    app.problema.working = true
                    if (response.data !== undefined) {
                        if (response.data.res === 'ok') {
                            app.$toast.success(response.data.msg, {icon: 'mdi-thumb-up'});
                            app.$vlf.setItem('problema_user', app.problema.user)
                            app.problema.testo = ''
                        } else {
                            app.$toast.error(response.data.msg, {icon: 'mdi-thumb-down', timeout: 10000});
                        }
                    }
                }).finally(() => {
                    app.problema.working = false
                    app.problema_dialog = false
                })
            },
            setupFloatingButtins(submenu) {
                // ritorno solo i gruppi che si intersecano
                let app = this
                if (submenu.floating_buttons !== undefined) {
                    let buttons = _.filter(submenu.floating_buttons, function (o) {
                        
                        if ((o.groups !== undefined) && (_.intersection(app.userdata.groups, o.groups).length > 0)) {
                            return true
                        }
                        
                        return !((o.admin) && (app.$store.state.user.userdata.admin === false));
                    });
                    
                    app.$set(app.floating_buttons, submenu.route.name, buttons)
                } else {
                    app.$set(app.floating_buttons, submenu.route.name, [])
                }
            },
            fabClick: function (event) {
                bus.$emit(event, 'blabla');
            },
            firstRoute: function (item) {
                // prima route valida del menu
                try {
                    let app = this;
                    let routes = _.filter(item.submenu, function (o) {
                        // ritorno solo i gruppi che si intersecano
                        if (o.groups === undefined) {
                            return true
                        }
                        if (app.userdata.is_superuser) {
                            return true
                        }
                        return _.intersection(app.userdata.groups, o.groups).length > 0;
                    });
                    if ((routes !== undefined) && (routes.length > 0)) {
                        return routes[0].route
                    } else {
                        return '/'
                    }
                } catch (e) {
                    return '/'
                }
                
            },
            PdfRendered: function () {
                this.pdfloading = false
            },
            PdfafterCreated: function () {
                this.pdfloading = true
            },
            async PdfopenHandler(PDFViewerApplication) {
                
                this.info = [];
                const info = await PDFViewerApplication.pdfDocument
                    .getMetadata()
                    .catch(function (e) {
                        console.log(e)
                    }).then(function (response) {
                        console.log(response)
                    })
                
                if (!info) return;
                const props = Object.keys(info.info);
                props.forEach((prop) => {
                    const obj = {
                        name: prop,
                        value: info.info[prop],
                    };
                    this.info.push(obj);
                });
                // PDFViewerApplication.pdfViewer.currentScale = 2
                PDFViewerApplication.pdfCursorTools.switchTool(1)
                
            },
            showPdf: function (item) {
                let app = this
                if (item.minio) {
                    app.pdf.url = item.minio
                } else {
                    app.pdf.url = undefined
                    if (item.articolo !== undefined) {
                        this.$work.post(`/arts/disegno/`, {'art': item.articolo, 'disegno': item.disegno}).then(response => {
                            if (response?.data?.minio) {
                                app.pdf.url = response.data.minio
                            } else {
                                let pdfurl = this.$workUrl + '/commesse/getfile/';
                                if (item.pdf === undefined) {
                                    
                                    app.pdf.url = undefined
                                    this.$work.post('/filekey/', {
                                        cmd: 'disegno',
                                        articolo: item.articolo,
                                        disegno: item.disegno
                                    }).then(function (response) {
                                            app.pdf.url = pdfurl + response.data.key
                                        }
                                    )
                                } else {
                                    app.pdf.url = pdfurl + item.pdf
                                }
                                
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    } else {
                        let pdfurl = this.$workUrl + '/commesse/getfile/';
                        if (item.pdf !== undefined) {
                            app.pdf.url = pdfurl + item.pdf
                        }
                    }
                }
                app.drawer = false
                
                app.pdf.overlay = true
                app.pdf.title = item.disegno
                document.title = 'Work - Programmi Laser'
            },
            checkVersion: async function () {
                let app = this
                if (app.totem) {
                    console.log("TOTEM")
                } else {
                    this.$work.get("/dashboard/").then(function (response) {
                        if (response !== undefined) {
                            let appVer = parseInt(app.$AppVersion.replaceAll('.', '')) || 0
                            let serVer = parseInt(response.data.ver.replaceAll('.', '')) || 0
                            if (appVer < serVer) {
                                app.$data.reloadSnack = true
                                setTimeout(function () {
                                    app.aggiorna()
                                }, 30000)
                            }
                            
                        }
                    }).catch(function (error) {
                        console.log(error)
                    })
                }
            },
            aggiorna: function () {
                window.location.reload();
            },
            updateClock: async function () {
                if (document.hasFocus()) {
                    let now = this.$moment();
                    this.$set(this.$data, 'clock', now.format('ll') + ' ' + now.format('LTS'));
                }
                
            }
            ,
            doLogout: function () {
                this.$router.push({name: 'Home'}).then(() => {
                    this.$work.logout(this);
                })
            }
            ,
            getBadge: function (submenu) {
                // questa viene lanciata ogni secondo, per cui tenere una cache
                let refreshtime = 30
                let app = this;
                if (app.badges[submenu.route.name] === undefined) {
                    app.badges[submenu.route.name] = {
                        timestamp: app.$moment().subtract(refreshtime, 'seconds'),
                        value: 0
                    }
                }
                if (app.$moment().subtract(refreshtime, 'seconds').isAfter(app.badges[submenu.route.name].timestamp)) {
                    app.badges[submenu.route.name].timestamp = app.$moment()
                    this.$work.getsilent(submenu.badge.url).then(function (response) {
                        if (response !== undefined) {
                            if (response.data !== undefined) {
                                try {
                                    app.badges[submenu.route.name].value = response.data.conteggio
                                } catch (e) {
                                    app.badges[submenu.route.name].value = 'n/d'
                                }
                            }
                        }
                    });
                }
                
                return app.badges[submenu.route.name].value;
            },
            
            
            barcodeListener(e) {
                // ATTENZIONE IL LETTORE BARCODE DEVE ESSERE CONFIGURATO CON LA LINGUA CORRETTA IN BASE ALL'SO
                let app = this
                let key = e.key
                
                
                if ((e.key) && ((e.key.length === 1) || (e.key === 'Enter'))) {
                    if (performance.now() - app.lastKeyTime > 200) {
                        /*
                        * SE TROPPO LENTO RESETTO
                        */
                        console.log(`KEY ${key} time:${Math.round(performance.now() - app.lastKeyTime)}  SLOW`)
                        app.newBarcode = false
                        app.barcodeData = undefined
                        app.lastKeyTime = performance.now()
                        app.lastKey = key
                        return
                    } else {
                        console.log(`KEY ${key} time:${Math.round(performance.now() - app.lastKeyTime)}  OK`)
                        app.lastKeyTime = performance.now()
                    }
                    
                    // lettura barcode su invio
                    if ((key === 'Enter') && (app.newBarcode === true) && (app.barcodeData !== undefined)) {
                        
                        let barcode = app.barcodeData
                        app.newBarcode = false
                        app.barcodeData = undefined
                        
                        app.$toast.success("Lettura Barcode " + barcode, {icon: 'mdi-barcode-scan'})
                        if (app.$refs.searchCb !== undefined) app.$refs.searchCb.reset()
                        
                        let re = /OD[RP]\d+/g
                        if (re.exec(barcode) !== null) {
                            
                            if (app.$route.name === 'av-operatore') {
                                bus.$emit('scanned-barcode-operatore', barcode)
                            } else if (app.$route.name === 'av-fase') {
                                bus.$emit('scanned-barcode-fase', barcode)
                            } else {
                                bus.$emit('scanned-barcode', barcode)
                                setTimeout(function () {
                                    console.info("BARCODE ROUTE PUSH", barcode)
                                    app.$router.push({name: 'Ordine', params: {codice: barcode}})
                                }, 200)
                            }
                            return
                        } else {
                            // un articolo ?
                            setTimeout(function () {
                                    console.info("BARCODE ROUTE PUSH", barcode)
                                    app.$router.push({name: 'Articolo', params: {codice: barcode}})
                                }, 200
                            )
                            
                        }
                        e.preventDefault()
                        
                    } else {
                        if (app.newBarcode) {
                            app.barcodeData += key;
                        } else {
                            app.newBarcode = true
                            
                            if (app.lastKey) {
                                app.barcodeData = app.lastKey
                                app.barcodeData += key
                                app.lastKey = undefined
                            } else {
                                app.barcodeData = key
                            }
                        }
                    }
                    
                }
            }
            
        },
        watch: {
            
            /*pdf: {
                handler: function (newVal) {
                    if (newVal.overlay === false) {
                        this.pdfloading = false
                    }
                }, deep: true
            },
            */
            '$route': {
                handler: function () {
                    this.totem = this.$route.query?.totem === 'true'
                }, deep: true
            },
            requestpin: function (newVal) {
                if (newVal) {
                    this.pin = ''
                }
            },
            searchSync: function (val) {
                this.search(val)
            },
            problema_dialog() {
                let app = this
                if (app.problema.user === '') {
                    app.$vlf.getItem('problema_user').then(operatore => {
                        if (operatore) app.problema.user = operatore
                    })
                }
            },
            searchModel(val) {
                let app = this
                if (val?._source !== undefined) {
                    app.$router.push({name: val._source.route, params: {codice: val._source.codice}}).then(function () {
                        setTimeout(function () {
                            app.$data.searchModel = null
                            app.$data.searchSync = null
                            app.$data.searchEntries = []
                        }, 50)
                    })
                    
                }
            },
            searchFilter: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$vlf.setItem('config_search', newVal)
                }
                this.search(this.searchSync)
            },
        },
        computed: {
            requestpin: function () {
                return this.$store.state.requestpin
            },
            isLoggedIn: function () {
                console.log(this.$store)
                return this.$store.state.user.isLoggedIn
            },
            userdata: function () {
                return this.$store.state.user.userdata
            },
            canSearch: function () {
                // Gli utenti del gruppo Fornitore o Cliente non possono fare ricerche
                if (this.userdata.groups !== undefined) {
                    if (this.userdata.groups.indexOf('Fornitore') !== -1) return false
                    return this.userdata.groups.indexOf('Cliente') === -1;
                }
                return false
            },
            subMenu: function () {
                let app = this
                if (this.$route.meta.menu !== undefined) {
                    if (this.$data.workMenu[this.$route.meta.menu] !== undefined) {
                        return _.filter(this.$data.workMenu[this.$route.meta.menu].submenu, function (o) {
                            
                            if (o.groups === undefined) {
                                if (app.floating_buttons[o.route.name] === undefined) {
                                    app.setupFloatingButtins(o)
                                }
                                
                                return true
                            }
                            if (app.userdata.admin) {
                                if (app.floating_buttons[o.route.name] === undefined) {
                                    app.setupFloatingButtins(o)
                                }
                                
                                return true
                            }
                            
                            if (_.intersection(app.userdata.groups, o.groups).length > 0) {
                                if (app.floating_buttons[o.route.name] === undefined) {
                                    app.setupFloatingButtins(o)
                                }
                                return true
                            }
                        });
                    }
                    return null
                }
                return null
            }
            ,
            areaTitle: function () {
                if (this.$route.meta.menu !== undefined) {
                    if (this.$data.workMenu[this.$route.meta.menu] !== undefined) {
                        return this.$data.workMenu[this.$route.meta.menu].title
                    }
                    return null
                }
                return null
            },
            areaIcon: function () {
                if (this.$route.meta.menu !== undefined) {
                    if (this.$data.workMenu[this.$route.meta.menu] !== undefined) {
                        return this.$data.workMenu[this.$route.meta.menu].icon
                    }
                    return null
                }
                return null
            }
        }
        ,
    }
</script>

<style>
.bgimage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('./assets/pexels-adrien-olichon-2387532.jpg') no-repeat center center;
    filter: brightness(0.2);
    background-size: cover;
    transform: scale(1.1);
}

.bgstripes {
    background-image: linear-gradient(45deg, #273624 25%, #424242 25%, #424242 50%, #273624 50%, #273624 75%, #424242 75%, #424242 100%);
    background-size: 56.57px 56.57px;
    background-repeat: repeat;
}
</style>