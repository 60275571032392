<template>
    <div ref="imgDisegno" class="d-flex justify-center" :style="{'width':realwidth}">
        <v-lazy>
            <v-img v-if="imagerurl && thumburl"
                   contain
                   @click.native.stop="showPdf"
                   @error="imageError"
                   class="m-2"
                   :style="{cursor: !missing? pdfShow?'zoom-in':'pointer':''}"
                   ref="vImg"
                   :width="realwidth!==undefined ? realwidth : width"
                   :height="height"
                   :lazy-src="thumburl"
                   :src="imagerurl"
            >
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular v-if="!missing"
                                             indeterminate
                                             :size="height/3"
                                             color="grey lighten-2"
                        >
                        </v-progress-circular>
                        <v-icon v-else
                                :size="height/2"
                                color="grey">mdi-image-broken
                        </v-icon>
                    </v-row>
                </template>
                <v-icon v-if="multipagina"
                        :style="{'position': 'absolute','color': 'red','right': `10px`,'bottom':'10px'}"
                        title="Multipagina">mdi-file-document-multiple
                </v-icon>
            
            </v-img>
            <v-img v-else
                   :width="realwidth!==undefined ? realwidth : width"
                   :height="height"
            >
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular
                        indeterminate
                        :size="height/3"
                        color="grey lighten-2"
                    >
                    </v-progress-circular>
                </v-row>
            </v-img>
        </v-lazy>
    </div>
</template>
<script>
    
    import Thumbor from "thumbor";
    import _ from "lodash";
    import {bus} from "../../main";
    
    export default {
        name: 'btDisegno',
        props: {
            articolo: {type: String, required: true},
            disegno: {type: String, required: true},
            minio: {type: String, required: false},
            width: {type: Number, default: 100},
            icon: {type: Boolean, default: false},
            multipagina: {type: Boolean, default: false},
            pdfShow: {type: Boolean, default: true},
            hidemissing: {type: Boolean, default: false},
        },
        data() {
            return {
                realwidth: undefined,
                metadata: undefined,
                refreshtimer: undefined,
                minio_url: undefined,
                missing: false,
            }
        },
        computed: {
            height() {
                if (this.realwidth) {
                    return this.realwidth * 0.7;
                } else {
                    return this.width * 0.7;
                }
            },
            imagerurl() {
                let thumbor = new Thumbor(this.$ThumborKey, this.$ThumborUrl);
                return thumbor.setImagePath(this.img).fitIn(`${this.width}`, "0").buildUrl()
            },
            thumburl() {
                let thumbor = new Thumbor(this.$ThumborKey, this.$ThumborUrl);
                return thumbor.setImagePath(this.img).fitIn("50", "35").buildUrl();
            },
            img() {
                let disegno = _.kebabCase(_.deburr(this.disegno.split('.').slice(0, -1).join('.'))) + '.jpg'
                return `${this.articolo[0].toLowerCase()}/${this.articolo.toLowerCase()}/${disegno}`;
            }
        },
        methods: {
            imageError: function (err) {
                console.log(`Rebuild missing image ${err}`)
                this.check_thumb()
            },
            showPdf: function () {
                if (this.pdfShow && !this.missing) {
                    bus.$emit('show-pdf', {'articolo': this.articolo, 'disegno': this.disegno, 'minio': this.minio_url})
                }
            },
            onResize(event) {
                this.realwidth = event[0].contentRect.width > 0 ? event[0].contentRect.width : this.width;
            },
            check_thumb() {
                let app = this;
                let thumbor = new Thumbor(app.$ThumborKey, app.$ThumborUrl);
                let metaurl = thumbor.metaDataOnly(true).setImagePath(app.img).buildUrl();
                let ts = app.$moment().toISOString()
                this.$work.thumbmeta(metaurl, {'ts': ts}).then((response) => {
                    app.metadata = response.data;
                }).catch((error) => {
                    // missing image, chiedo al server di generarla
                    if (error.response?.status === 404) {
                        app.refreshtimer = setTimeout(function () {
                            if (app.$refs.vImg) {
                                app.$refs.vImg.loadImage()
                            }
                            
                        }, 3000)
                        this.$work.post('/arts/generajpeg/', {
                            'pdf': `${app.articolo[0]}/${app.articolo}/${app.disegno}`,
                            'img': app.img
                        }, true).catch((error) => {
                            if (error.response?.status === 404) {
                                clearTimeout(app.refreshtimer)
                                app.missing = true
                            }
                        })
                        
                    }
                    
                })
            }
        },
        mounted() {
            this.ro = new ResizeObserver(_.debounce(this.onResize))
            this.ro.observe(this.$refs.imgDisegno)
        },
        
        beforeDestroy() {
            this.ro.unobserve(this.$refs.imgDisegno)
            this.refreshtimer && clearTimeout(this.refreshtimer)
        }
    }
</script>

<style>
.multipagina {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
</style>