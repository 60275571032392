<template>
    <v-container fluid class="flex-column overflow-y-auto" style="max-height: 90vh;">
        <div class="mb-5 display-1">
            <v-row dense align="center">
                <v-col>
                    Non Conformità da aggiornare
                </v-col>
                <v-col style="text-align: right">
                    <bt-si-no-null name="risolte" :status="filtro.risolte" :callback="fromButton" label="Risolte"/>
                </v-col>
            
            </v-row>
        </div>
        <m-loading :loading="loading"/>
        <v-data-iterator v-if="righe"
                         :items="filteredRighe"
                         :search.sync="search"
                         :items-per-page="50"
                         :footer-props="{
                'items-per-page-options': [50,100,500]
            }"
        >
            <template v-slot:header>
                <v-toolbar
                    class="mb-1"
                >
                    <v-row align="center">
                        <v-col>
                            <v-text-field
                                v-model="search"
                                clearable
                                flat
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                label="Filtra"
                            ></v-text-field>
                        </v-col>
                        <v-col class="flex-shrink-1 flex-grow-0" v-if="howmanychecks > 0">
                            
                            
                            <v-dialog
                                v-model="confirmdialog"
                                width="40vw"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color="info" :disabled="loading">Risolvi
                                        Selezionate {{
                                            howmanychecks
                                        }}
                                    </v-btn>
                                </template>
                                <v-card style="border:red 2px solid">
                                    <v-card-title class="headline lighten-2">
                                        Non Conformità
                                    </v-card-title>
                                    
                                    <v-card-text class="text-xl-h4">
                                        <template v-if="howmanychecks > 1">
                                            Confermare la risoluzione di {{ howmanychecks }} non-conformità selezionate?
                                        </template>
                                        <template v-else>
                                            Confermare la risoluzione della non-conformità selezionata?
                                        </template>
                                    
                                    </v-card-text>
                                    
                                    <v-divider></v-divider>
                                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="info"
                                            
                                            @click="confirmdialog = false"
                                        >
                                            NO
                                        </v-btn>
                                        <v-btn
                                            color="red"
                                            text
                                            @click="confirmdialog = false;risolviNc();"
                                        >
                                            SI
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        
                        
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr class="caption text-uppercase">
                            <th scope="col" rowspan="2">
                                Risolvi
                            </th>
                            <th scope="col">Numero</th>
                            <th scope="col">Riferimento</th>
                            <th scope="col">Data Ins</th>
                            <th scope="col">Articolo</th>
                            <th scope="col">Descrizione</th>
                            <th scope="col">Gestione</th>
                            <th scope="col">Foto</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="riga in props.items" v-bind:key="`${riga.pk}-${riga.articolo}`"
                            :class="[`priorita-${riga.priorita}`,`checkedriga-${riga.checked}`]"
                            @click="onRowClick(riga,$event)">
                            <td>
                                <template v-if="riga.risolvendo">
                                    <v-progress-circular
                                        :size="20"
                                        :width="2"
                                        indeterminate
                                    ></v-progress-circular>
                                </template>
                                <template v-else>
                                    <v-checkbox v-model="riga.checked"></v-checkbox>
                                </template>
                            </td>
                            <td>
                                {{ riga.numero }}
                            </td>
                            <td>
                                {{ riga.rif }}
                            </td>
                            <td>{{ riga.data_inserimento | moment('DD/MM/Y') }}</td>
                            
                            <td>
                                <bt-articolo :codice="riga.articolo"/>
                            </td>
                            <td style="white-space: nowrap">{{ riga.articolo_descrizione }}</td>
                            <td>
                                {{ riga.nc_gestione }}
                            </td>
                            <td>
                                <v-lazy>
                                    <files-n-c :numero="riga.numero" :riga="riga.riga"/>
                                </v-lazy>
                            </td>
                            <td>
                                <nc-edit :input="riga" :articolo="riga.articolo" :getdata="getData"/>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
    
    import _ from 'lodash';
    import NcEdit from "./include/NcEdit";
    import BtArticolo from "../include/BtArticolo";
    import MLoading from "../include/MLoading";
    import {teamLeaderMixin} from "../../mixins/teamleader";
    import BtSiNoNull from "../include/BtSiNoNull.vue";
    import FilesNC from "../articoli/include/FilesNc.vue";
    
    export default {
        name: "RiepilogoNc",
        mixins: [teamLeaderMixin],
        components: {FilesNC, BtSiNoNull, NcEdit, BtArticolo, MLoading},
        data: () => ({
            wantsTeamLeader: true,
            otherGroups: ['Amministrazione', 'Qualità', 'Officina'],
            checkall: false,
            confirmdialog: undefined,
            timer: '',
            search: '',
            righe: undefined,
            total_items: undefined,
            loading: true,
            dialog: false,
            dialog_data: undefined,
            filtro: {
                risolte: 'no',
            }
        }),
        beforeDestroy() {
            clearInterval(this.timer)
        },
        mounted() {
            this.timer = setInterval(this.refreshData, 300000);
            document.title = `Work - Nc da aggiornare`
            this.$vlf.getItem(`filtro_nc_da_aggiornare`).then(filtro => {
                if (filtro) this.filtro = filtro
                return this.$vlf.getItem(`nc_da_fare`).then(righe => {
                    if (righe) this.righe = righe
                })
            }).finally(() => this.getData())
            
        },
        watch: {
            socketMessage(newVal) {
                if (newVal.route === 'nc') {
                    this.getData(true)
                }
            },
        },
        computed: {
            filteredRighe: function () {
                let items = {}
                if (this.filtro.risolte === 'si') {
                    items = _.filter(this.righe, o => {
                        return o.risolta === 2
                    })
                } else if (this.filtro.risolte === 'no') {
                    items = _.filter(this.righe, o => {
                        return o.risolta !== 2
                    })
                } else {
                    items = this.righe
                }
                return items
            },
            howmanychecks: function () {
                if (this.righe) {
                    return this.righe.filter(riga => riga.checked).length
                }
                return 0
            },
            socketMessage() {
                return this.$store.state.socketmessage
            },
        },
        methods: {
            fromButton(name, value) {
                this.$set(this.$data.filtro, name, value)
                this.$vlf.setItem(`filtro_nc_da_aggiornare`, this.filtro)
            },
            risolvi: async function (riga) {
                this.$set(riga, 'risolvendo', true)
                let formData = new FormData();
                
                formData.append('risolta', riga.checked);
                formData.append('numero', riga.numero);
                formData.append('riga', riga.riga);
                
                this.$work.post('/arts/nc/' + riga.articolo + '/', formData).then(() => {
                }).finally(() => {
                    setTimeout(() => {
                        this.$set(riga, 'risolta', 2)
                        this.$set(riga, 'checked', false)
                        this.$set(riga, 'risolvendo', false)
                    }, 500)
                })
            },
            risolviNc: async function () {
                _.each(this.righe, riga => {
                    if (riga.checked) {
                        this.risolvi(riga)
                    }
                })
            },
            onRowClick(item, event) {
                if (event.target.nodeName !== 'TD') {
                    return
                }
                this.$set(item, 'checked', !item.checked)
            },
            refreshData: function () {
                if (document.hasFocus()) {
                    this.getData()
                }
            },
            setData: function (nc) {
                this.loading = true
                this.$work.post('/arts/nc/' + this.codice + '/', nc).then(() => {
                    this.dialog_data = undefined
                    this.dialog = false
                    this.loading = false
                })
            },
            getData: async function () {
                let app = this
                app.loading = true
                this.$work.get(`/arts/nc/?dafare=true`).then(function (response) {
                    if (response !== undefined) {
                        app.righe = response.data
                        _.each(app.righe, function (item) {
                            app.$set(item, 'checked', false)
                            app.$set(item, 'risolvendo', false)
                        })
                    }
                }).finally(() => {
                    app.loading = false
                    app.$vlf.setItem(`nc_da_fare`, app.righe)
                })
            }
        }
    }
</script>

<style scoped>
::v-deep .efficente {
    background-color: rgba(0, 128, 0, 0.21);
}

::v-deep .inefficente {
    background-color: rgba(255, 0, 0, 0.21);
}

::v-deep th {
    height: auto !important;
}

/*
::v-deep td {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: gray;
}

::v-deep td:last-child {
    border-right-style: none;
}
*/
::v-deep .checkedriga-true {
    background-color: rgb(0 200 0 / 20%) !important;
}

::v-deep .checkedriga-true:hover {
    background-color: rgb(0 255 0 / 20%) !important;
}
</style>