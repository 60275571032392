export const moduleUser = {
    state: () => ({
        isLoggedIn: false,
        userdata: {},
        token: ""
    }),
    mutations: {
        userdata(state, userdata) {
            state.userdata = userdata;
        },
        isLoggedIn(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        token(state, token) {
            state.token = token;
        },

    },
    actions: {}, getters: {
        token: (state) => state.token,
        userdata: (state) => state.userdata,
        isLoggedIn: (state) => state.isLoggedIn,
        hasGroups: (state) => (perms) => {
            if (state.userdata.admin) {
                return true;
            }
            if (typeof perms === "string") {
                perms = [perms];
            }
            for (let perm of perms) {
                if (state.userdata.groups.includes(perm)) {
                    return true;
                }
            }
            return false;
        }
    }
}