import axios from 'axios';

import {captureException} from "@sentry/vue";
import gravatar from 'gravatar';

axios.interceptors.response.use((response) => response, (error) => {
    if (typeof error.response === 'undefined') {
        window.vm.$toast.error('Errore di rete', {icon: 'mdi-lan-disconnect'});
    }
    return Promise.reject(error)
})

/*
 Work Api Plugin
*/


function WorkApi(url, store, version) {
    this.url = url
    this.store = store
    this.version = version

    this.headers = function () {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-App-Version': this.version
        }
        if (this.store.getters.token) {
            headers['Authorization'] = 'Token ' + this.store.getters.token
        }
        return headers
    }


    this.thumbmeta = function (url, params = {}) {
        let HTTP = axios.create({
            timeout: 60001
        });
        // //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return new Promise((resolve, reject) => {
            HTTP.get(url, {params: params})
                .then(response => {
                    if ((response.data !== null) && (response.data !== undefined)) {
                        resolve(response);
                    } else {
                        this.ErrorRedirect(response)
                        reject(new Error(`apiget missing data ${url}`));
                    }
                })
                .catch(error => {
                    reject(error)
                });
        })
    }
    this.sha256 = async function (message) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message.toLowerCase());

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        return hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    }
    this.logout = function (app) {
        app.$store.commit('isLoggedIn', false);
        app.$store.commit('userdata', {});
        app.$store.commit('avatar', {});
        app.$store.commit('token', "");
        app.$vlf.setItem('avatar', {});
    }

    this.urlContentToDataUri = async function (url, retry = 10) {
        return fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise(callback => {
                let reader = new FileReader();
                reader.onload = function () {
                    callback(this.result)
                };
                reader.readAsDataURL(blob);
            })).catch(error => {
                console.log(error);
                if (retry > 0) {
                    return this.urlContentToDataUri(url, retry - 1)
                }
            });
    }

    this.getAvatar = async function () {
        // recupera il da evatar
        let image_url = ''
        let profile_url = ''
        let image_data = ''
        if (this.store.getters.isLoggedIn) {
            if (this.store.getters.userdata.email) {
                image_url = gravatar.url(this.store.getters.userdata.email, {d: 'identicon'});
                profile_url = gravatar.profile_url(this.store.getters.userdata.email)
            } else {
                image_url = gravatar.url(this.store.getters.userdata.username + '@mail.mecomsrl.com', {d: 'identicon'});
                profile_url = gravatar.profile_url(this.store.getters.userdata.username + '@mail.mecomsrl.com');
            }
            image_url = "https://" + image_url.split("//")[1]
            profile_url = "https://" + profile_url.split("//")[1]
            image_data = await this.urlContentToDataUri(image_url)
            return {
                'avatar': image_url,
                'profile': profile_url,
                'image_data': image_data
            }
        }

    }
    this.tokenlogin = async function (token) {
        let res = await axios.post(this.url + 'user-token/', {'token': token}, {timeout: 60001})
        if (res.status === 200) {
            let vm = window.vm;
            let authUser = {};
            authUser.data = res.data;
            authUser.token = res.data.token;
            vm.$store.commit('isLoggedIn', true);
            vm.$store.commit('userdata', authUser.data);
            vm.$store.commit('token', authUser.token);
            return true;
        } else {
            return false;
        }
    }

    this.put = function (url, data) {
        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        // //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return HTTP.put(url, data).catch(this.ErrorNoRedirect);
    }
    this.post = function (url, data, silent = false, custom_headers = {}) {

        let headers = this.headers()
        if (custom_headers) {
            headers = {...headers, ...custom_headers}
        }

        let HTTP = axios.create({
            baseURL: this.url,
            headers: headers,
            timeout: 60001
        });
        // //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        if (silent) {
            return HTTP.post(url, data)
        } else {
            return HTTP.post(url, data).catch(this.ErrorNoRedirect);
        }

    }

    this.patch = function (url, data) {

        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        ////axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return HTTP.patch(url, data).catch(this.ErrorNoRedirect);
    }

    this.getsilent = function (url) {

        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        ////axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return HTTP.get(url).catch(function (error) {
            console.log(error);
        });
    }
    this.form = function (url, callback) {
        /*
        Prova di build automatico form
         */
        let formConfig = {rules: {}, elements: []};
        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        ////axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        HTTP.options(url).then(res => {
            let items = res.data.actions.PUT;
            for (let item_id in items) {
                let item = items[item_id];
                // aggiungo il campo su elements
                let element = {tag: 'el-input', item: {label: ''}, detail: {name: item_id}};
                let rules = [];
                element['item']['label'] = item.label;
                element['detail']['name'] = item_id;

                if (item.type === "integer") {
                    element['detail']['type'] = 'integer';
                }

                formConfig['elements'].push(element);


                // aggiungo le regole
                if (item.required) {
                    rules.push({required: true, message: 'Campo obbligarorio'});
                }
                if (item.max_length) {
                    rules.push({max: item.max_length, message: 'Lunghezza massima ' + item.max_length + ' caratteri'})
                }
                if (rules.length > 0) {
                    // aggiungo le regole se presenti
                    formConfig['rules'][item_id] = rules;
                }
            }
            callback(formConfig);
        }).catch(error => {
            this.$toast.error(error, {icon: 'mdi-alert', timeout: 10000});
        });
    }

    this.ErrorRedirect = function (error) {
        if (error.response !== undefined) {
            if (error.response.status === 401) {
                if (window.vm.$route.path !== '/login') {
                    // Autorizzazione richiesta per questa pagina !
                    window.vm.$toast.error('Autorizzazione Richiesta per questa pagina: ' + window.vm.$route.path, {icon: 'mdi-alert'});
                    window.vm.$router.push({name: 'Login', query: {next: window.vm.$route.path}});
                    // eseguoil logout , probabilmente è scaduto il token
                    if (this.store.getters.isLoggedIn) {
                        this.logout(window.vm);
                    }
                }
            } else if (error.response.status === 403) {
                // Autorizzazione richiesta per questa pagina !
                if (window.vm.$route.path !== '/login') {
                    window.vm.$toast.error('Livello Autorizzazione non Sufficente: ' + window.vm.$route.path, {icon: 'mdi-alert'});
                    window.vm.$router.push({name: 'Login', query: {next: window.vm.$route.path}});
                }
            } else if (error.response.status === 404) {
                // Missing Ojetto
                window.vm.$toast.error('Oggetto non trovato', {icon: 'mdi-alert', timeout: 5000, showClose: true});
            } else if (error.response.status === 204) {
                // Empty response
                console.log(error.response);
            } else {
                window.vm.$toast.error('Errore Applicazione ', {icon: 'mdi-alert'});
            }
        } else {
            window.vm.$toast.error('Errore Connessione ', {icon: 'mdi-alert'});
        }

    }

    this.ErrorNoRedirect = function (error) {
        if (error.response !== undefined) {
            if (error.response.status === 401) {
                if (window.vm.$route.path !== '/login') {
                    // Autorizzazione richiesta per questa pagina !
                    window.vm.$toast.error('Autorizzazione Richiesta per questa pagina: ' + window.vm.$route.path, {
                        icon: 'mdi-alert',
                        timeout: 10000
                    });
                    // resetto il token per sicurezza
                    if (this.store.getters.isLoggedIn) {
                        this.logout(window.vm);
                    }
                }
            } else if (error.response.status === 403) {
                // Autorizzazione richiesta per questa pagina !
                if (window.vm.$route.path !== '/login') {
                    window.vm.$toast.error('Livello Autorizzazione non Sufficente: ' + window.vm.$route.path, {
                        icon: 'mdi-alert',
                        timeout: 10000
                    });
                }
            } else {
                window.vm.$toast.error('Errore Applicazione ', {icon: 'mdi-alert'});
            }
        } else {
            window.vm.$toast.error('Errore Connessione ', {icon: 'mdi-alert'});
        }

    }

    this.delete = function (url) {
        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return HTTP.delete(url).catch(this.ErrorNoRedirect);
    }

    this.bot = function (cmd, query) {
        let url = '/bot/' + cmd + '/'
        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return HTTP.post(url, {'query': query}).catch(this.ErrorNoRedirect);
    }

    this.get = function (url, params = {}, silent = false, abortController = null) {

        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001,
            signal: abortController ? abortController.signal : null
        });
        //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return new Promise((resolve, reject) => {
            HTTP.get(url, {params: params})
                .then(response => {
                    if ((response.data !== null) && (response.data !== undefined)) {
                        resolve(response);
                    } else {
                        this.ErrorRedirect(response)
                        reject(new Error(`apiget missing data ${url}`));
                    }
                })
                .catch(error => {
                    if (silent) {
                        resolve({})
                    } else if (error.code === 'ERR_CANCELED') {
                        console.log('error', error.message, url)
                        resolve(undefined)
                    } else {
                        captureException(error)
                        this.ErrorRedirect(error)
                        reject(error)
                    }
                });
        })
    }

    this.updatepass = function (form) {
        let HTTP = axios.create({
            baseURL: this.url,
            headers: this.headers(),
            timeout: 60001
        });
        //axiosRetry(HTTP, {retries: 10, retryDelay: //axiosRetry.exponentialDelay});
        return HTTP.post('/rest-auth/password/change/', form);
    }
    this.login = function (form) {
        return axios.post(this.url + 'token-auth/', form, {timeout: 60001});
    }
}

export default {
    install: (app, options) => {
        console.log("Api plugin")
        let api = new WorkApi(options.url, options.store, options.version);
        window.work_api = api;
        app.prototype.$work = api;
    }
}









