import store from "../../store"

const ControlloTempi = () => import("./ControlloTempi")
const CommesseInLavorazione = () => import("./CommesseInLavorazione")
const CommesseLavorate = () => import("./CommesseLavorate")
const ControlloOdp = () => import("./ControlloOdp")
const HistoryDip = () => import("./HistoryDip")
const StatoDips = () => import("./StatoDips")

const ifAuthenticated = async (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
    } else if (to.query.token) {
        let res = await window.work_api.tokenlogin(to.query.token)
        if (res) {
            delete (to.query.token)
            next(to)
        } else {
            next('/login')
        }
    } else {
        next('/login')
    }
}

export default [
    {
        path: '/tempi/controllo/',
        name: 'tempi-controllo',
        meta: {menu: 'tempi'},
        component: ControlloTempi,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/inlavorazione/',
        name: 'tempi-inlavorazione',
        meta: {menu: 'tempi'},
        component: CommesseInLavorazione,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/lavorate/',
        name: 'tempi-lavorate',
        meta: {menu: 'tempi'},
        component: CommesseLavorate,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/controllodp/',
        name: 'tempi-controllodp',
        meta: {menu: 'tempi'},
        component: ControlloOdp,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/statodips/:matricola',
        name: 'HistoryDip',
        meta: {menu: 'tempi'},
        component: HistoryDip,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tempi/statodips/',
        name: 'StatoDips',
        meta: {menu: 'tempi'},
        component: StatoDips,
        beforeEnter: ifAuthenticated
    },
]