import ProgrammiPiega from "@/components/avanzamenti/ProgrammiPiega.vue";
import store from "../../store"

const Operatori = () => import("./Operatori")
const Operatore = () => import("./Operatore")
const Fase = () => import("./Fase")
const Fasi = () => import("./Fasi")

const Schedulazioni = () => import("./Schedulazioni")
const Schedulazione = () => import("./Schedulazione")

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/av/operatori/:presente?/:reparto?/:attivo?',
        name: 'av-operatori',
        meta: {menu: 'avanzamenti'},
        component: Operatori,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/av/operatore/:matricola/:nome/:ordine?/:operazione?',
        name: 'av-operatore',
        meta: {menu: 'avanzamenti'},
        component: Operatore,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/av/fasi/',
        name: 'av-fasi',
        meta: {menu: 'avanzamenti'},
        component: Fasi,
        beforeEnter: ifAuthenticated
    },

    {
        path: '/av/fase/:fase/:ordine?/:operazione?',
        name: 'av-fase',
        meta: {menu: 'avanzamenti'},
        component: Fase,
        beforeEnter: ifAuthenticated
    },

    {
        path: '/av/schedulazioni/',
        name: 'av-schedulazioni',
        meta: {menu: 'avanzamenti'},
        component: Schedulazioni,
        beforeEnter: ifAuthenticated
    },

    {
        path: '/av/schedulazione/:fase',
        name: 'av-schedulazione',
        meta: {menu: 'avanzamenti'},
        component: Schedulazione,
        beforeEnter: ifAuthenticated
    },

    {
        path: '/av/programmipiega',
        name: 'av-progpiega',
        meta: {menu: 'avanzamenti'},
        component: ProgrammiPiega,
        beforeEnter: ifAuthenticated
    },
]