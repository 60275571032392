const RichiestaRicambi = () => import("./RichiestaRicambi")
const MagazzinoUbicazioni = () => import("./MagazzinoUbicazioni")
const PickListBreton = () => import("./PickListBreton")
const Lamiere = () => import("./Lamiere")
const Tubi = () => import("./Tubi")
const PreparazioneOrdini = () => import("./PreparazioneOrdini")
const Svansi = () => import("./Svansi")
const FornitoriAttivi = () => import("./FornitoriAttivi")

const MagazzinoFornitore = () => import("../fornitore/MagazzinoFornitore")
const RiepilogoFornitore = () => import("../fornitore/RiepilogoFornitore")
import store from "../../store"

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }
    next('/login')
}

export default [
    {
        path: '/magazzino/ubicazioni/',
        name: 'MagazzinoUbicazioni',
        meta: {menu: 'magazzino'},
        component: MagazzinoUbicazioni,
        beforeEnter: ifAuthenticated
    }, {
        path: '/magazzino/picklist_breton/',
        name: 'PickListBreton',
        meta: {menu: 'magazzino'},
        component: PickListBreton,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/lamiere/',
        name: 'Lamiere',
        meta: {menu: 'magazzino'},
        component: Lamiere,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/tubi/',
        name: 'Tubi',
        meta: {menu: 'magazzino'},
        component: Tubi,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/ricambi/:categoria?',
        name: 'Ricambi',
        meta: {menu: 'magazzino'},
        component: RichiestaRicambi,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/preparazione/',
        name: 'PreparazioneOrdini',
        meta: {menu: 'magazzino'},
        component: PreparazioneOrdini,
        beforeEnter: ifAuthenticated
    }, {
        path: '/magazzino/svansi/',
        name: 'Svansi',
        meta: {menu: 'magazzino'},
        component: Svansi,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/fornitori/',
        name: 'Fornitori',
        meta: {menu: 'magazzino'},
        component: FornitoriAttivi,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/fornitore/:codice',
        name: 'fornitori-attivo',
        meta: {menu: 'magazzino'},
        component: MagazzinoFornitore,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/magazzino/fornitore_riepilogo/:codice',
        name: 'fornitori-riepilogo',
        meta: {menu: 'magazzino'},
        component: RiepilogoFornitore,
        beforeEnter: ifAuthenticated
    },
]