import Vue from 'vue';
import Vuex from 'vuex';


import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';
import {moduleUser} from "./modules/user";


Vue.use(Vuex);

let userState = createPersistedState(
    {
        key: 'vuex_user',
        paths: ['user.isLoggedIn', 'user.userdata', 'user.token', 'user.hasGroups'],
        storage: {
            getItem: (key) => Cookies.get(key),
            // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
            setItem: (key, value) => {
                Cookies.set(key, value, {expires: 30, secure: false})
            },
            removeItem: (key) => Cookies.remove(key),
        },
    }
)

//init store
const store = new Vuex.Store({
    modules: {
        user: moduleUser,
    },
    state: () => ({
        version: undefined,
        socketmessage: undefined,
        caporeparto: undefined,
        requestpin: undefined,
    }),
    mutations: {
        version(state, version) {
            state.version = version;
        },
        socketmessage(state, payload) {
            state.socketmessage = payload
        },
        caporeparto(state, payload) {
            state.caporeparto = payload
        },
        requestpin(state, payload) {
            state.requestpin = payload
        }
    },
    actions: {
        socketmessage({commit}, payload) {
            commit('socketmessage', payload)

        }
    },
    getters: {
        version: state => {
            return state.version
        },
        socketmessage: state => {
            return state.socketmessage
        },
        caporeparto: state => {
            return state.caporeparto
        },
        requestpin: state => {
            return state.requestpin
        }
    }, plugins: [userState]
});


// store.registerModule('ciaone', moduleFasiOperatore)
export default store